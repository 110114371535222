import { Environment } from '@/dtos/Environment';
import Axios, { AxiosInstance } from 'axios';
import BaseService from './BaseService';
import FormService from './FormService';
import FormServiceProxy from './FormServiceProxy';
import DynamicLDService from './DynamicLDService';
import JourneyService from './JourneyService';

export default class ServiceInitializer {
    private readonly client: AxiosInstance = null;

    constructor(client?: AxiosInstance) {
        this.client = client || this.createClient();

        this.checkEnvironment();
    }

    public init(instanceToken: string): Record<string, BaseService> {
        return {
            formService: new FormService(this.client, instanceToken),
            journeyService: new JourneyService(this.client, instanceToken),
            dynamicLDService: new DynamicLDService(this.client, instanceToken),
        };
    }

    private createClient(): AxiosInstance {
        const baseURL = 'https://app-api.preview.solucx.com.br';
        return Axios.create({ baseURL });
    }

    private checkEnvironment(): void {
        const urlParameters = new URLSearchParams(window.location.search);
        const environment = urlParameters.get('environment') as Environment;

        if (environment === Environment.SANDBOX) {
            this.invokeFakeService();
        }
    }

    private invokeFakeService(): void {
        new FormServiceProxy(this.client).invokeFakeService();
    }
}
